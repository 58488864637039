<template>
  <vs-popup class="sm:popup-w-60" title="Create Payment Request" :active="isActive" v-on:update:active="emitModalIsActive">
    <div>
      <!------------ errors ------------->
      <div class="vx-row">
        <div class="vx-col w-full">
          <ValidationErrors :errors="errors"/>
        </div>
      </div>

      <!------------ header ------------->
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">No. PR</label>
          <vs-input class="w-full" value="Generate Otomatis" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">PR Date *</label>
          <flat-pickr class="w-full" v-model="data.tgl_pr"></flat-pickr>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">PR Due Date *</label>
          <flat-pickr class="w-full" v-model="data.tgl_due"></flat-pickr>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">PV Items</label>
          <vs-input class="w-full" :value="paymentVouchers.length + ' PV'" readonly/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">PV Grand Total Balance</label>
          <v-money class="w-full" :value="grandTotalBalancePv" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs text-success font-medium">Nilai PR</label>
          <v-money class="w-full" v-model="data.nilai_pr" :danger="data.nilai_pr > grandTotalBalancePv || !data.nilai_pr"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Description *</label>
          <vs-input class="w-full" placeholder="Description" v-model="data.keterangan"/>
        </div>
      </div>

      <!------------ footer ------------->
      <div class="vx-row float-right mt-6">
        <div class="vx-col w-full">
          <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Cancel</vs-button>
          <vs-button :disabled="isLoading" @click="confirmCreatePaymentRequest">
            <span v-if="isLoading" class="animate-pulse">Creating...</span>
            <span v-if="!isLoading">Create PR</span>
          </vs-button>
        </div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import ApRepository from '@/repositories/accounting/ap-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'CreatePaymentRequest',
  props: {
    isActive: { required: true, type: Boolean },
    paymentVouchers: { required: true, type: Array }
  },
  components: {
    ValidationErrors,
    VMoney,
    flatPickr
  },
  data () {
    return {
      errors: null,
      isLoading: false,
      data: {
        tgl_pr: moment().format('YYYY-MM-DD'),
        tgl_due: moment().format('YYYY-MM-DD'),
        nilai_pr: null,
        keterangan: null
      }
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.data.nilai_pr = this.grandTotalBalancePv
        this.data.keterangan = `Pengajuan pembayaran untuk ${this.paymentVouchers.length} PV`
      }
    }
  },
  computed: {
    grandTotalBalancePv () {
      return _.sumBy(this.paymentVouchers, item => parseFloat(item.total_balance))
    }
  },
  methods: {
    createPaymentRequest () {
      this.errors = null
      this.isLoading = true

      const params = this.buildParams()
      ApRepository.createPaymentRequest(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        tgl_pr: this.data.tgl_pr,
        tgl_due: this.data.tgl_due,
        grand_total_balance_pv: this.grandTotalBalancePv,
        nilai_pr: this.data.nilai_pr,
        keterangan: this.data.keterangan,
        paymentVouchers: _.map(this.paymentVouchers, item => {
          return {
            id: item.id,
            id_proyek: item.id_proyek
          }
        })
      }
    },

    confirmCreatePaymentRequest () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: 'Konfirmasi',
        text: 'Yakin ingin mengajukan payment request?',
        acceptText: 'Ya, Lanjutkan',
        cancelText: 'Batal',
        accept: () => {
          this.createPaymentRequest()
        }
      })
    },

    onSuccess () {
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
      this.notifySuccess('Berhasil membuat payment request')
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
